<template>
  <div class="container-fluid shadow p-3 pe-5 ps-5 mb-5">
    <div class="row flex-nowrap">
      <div class="col navbar-light p-0 m-0">
        <span class="h3">Profile</span>
        <div class="float-end">
          <display-picture :is-left="true"></display-picture>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <form @submit="save" class="row" v-if="user">
      <div class="col-sm-2 mb-3">
        <display-image :avatar-url="user.avatarUrl" className="w-100" />
      </div>
      <div class="col-sm-10 mb-3">
        <div class="h5 mb-2">Display Picture</div>
        <FileManager field="avatarUrl" :object="user"></FileManager>
      </div>
      <div class="col-sm-6 mb-3">
        <div class="h5 mb-2">Name</div>
        <input type="text" class="form-control" placeholder="Name" v-model="user.name">
      </div>
      <div class="col-sm-6 mb-3">
        <div class="h5 mb-2">Username</div>
        <input type="text" class="form-control" placeholder="Username" v-model="user.username" readonly>
      </div>
      <div class="col-sm-12 mb-3">
        <div class="h5 mb-2">Nickname</div>
        <input type="text" class="form-control" placeholder="Nickname" v-model="user.nickname">
      </div>
      <div class="col-sm-12 mb-3">
        <div class="h5 mb-2">Bio</div>
        <textarea class="form-control" placeholder="Bio" v-model="user.bio">
        </textarea>
      </div>
      <div class="col-sm-6 mb-3">
        <div class="h5 mb-2">Email</div>
        <input type="email" class="form-control" placeholder="Email" v-model="custom.email" readonly>
      </div>

      <div class="col-sm-6 mb-3">
        <div class="h5 mb-2">Password</div>
        <input type="password" class="form-control" placeholder="********" v-model="user.newPassword">
      </div>

      <div class="col-sm-12 mb-3">
        <button class="btn btn-primary w-100">Save</button>
      </div>

    </form>
  </div>
</template>

<script>
import {services} from '../service/user-api'
import DisplayPicture from 'node-back-client/src/components/DisplayPicture'
import FileManager from "node-back-client-input-extension/src/components/FileManager";
import DisplayImage from 'node-back-client/src/components/DisplayImage'
export default {
  name: "Account",
  components: {
    FileManager,
    DisplayPicture,
    DisplayImage
  },
  data() {
    return {
      user: null,
      custom:{},
    }
  },
  async created() {
    this.user = await services.user.GetUserInfo();
    this.custom.email = this.user.username + "@egcextremeunrealtechnology.com";
  },
  methods:{
    async save() {
      await services.user.Save(this.user);
      return null;
    }
  }
}
</script>